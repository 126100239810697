<template>
<router-view></router-view>
</template>

<script>

export default {
    name: "App",
    data() {
        return {};
    },

    methods: {},

    mounted() {

    }
};
</script>

<style lang="scss" scoped>

</style>
